<template>
  <v-menu
    ref="menu"
    v-model="visible"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="input"
        v-model="dateFormatted"
        v-mask="'##/##/####'"
        hint="Formato: DD/MM/AAAA"
        :rules="[...rules, isValid]"
        persistent-hint
        append-icon="mdi-calendar"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        @click="selectAll"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="visible = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
import { mask } from "vue-the-mask";
mask.masked = false;

export default {
  name: "k-input-date",

  props: {
    value: String,
    rules: { type: Array, default: () => [] }
  },

  directives: {
    mask
  },

  data() {
    return {
      date: null,
      dateFormatted: null,
      visible: false
    };
  },

  watch: {
    /**
     * Ao alterar a data em us
     */
    date() {
      this.dateFormatted = this.dateUStoBR(this.date);
      this.$emit("input", this.date);
    },

    value(newVal, oldVal) {
      if (!oldVal) {
        this.date = newVal;
      }

      // Se o novo valor estiver vazio mas o valor antigo
      // estava preenchido. Evita que crie um loop de valores
      // edições pois o this.date altera o valor de value
      if (!newVal && oldVal) {
        this.date = null;
      }
    },

    /**
     * Ao alterar a data em br
     */
    dateFormatted(val) {
      if (this.isValidDate(val)) {
        this.date = moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
      }

      if (!val) {
        this.date = null;
      }
    }
  },

  computed: {
    /**
     * Valida se a data digitada no em pt-br está válida
     * e com 10 caracteres
     */
    isValid() {
      if (!this.dateFormatted || this.isValidDate()) return true;
      return "Data inválida";
    }
  },

  methods: {
    isValidDate() {
      if (this.dateFormatted && this.dateFormatted.length == 10) {
        const date = moment(this.dateFormatted, "DD/MM/YYYY");
        if (date.isValid()) return true;
      }

      return false;
    },
    dateUStoBR(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    dateBRtoUS(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    selectAll() {
      const el = this.$refs.input.$el;
      const input = el.querySelector("input");
      input.select();
    }
  },
  mounted() {
    if (this.value) {
      this.date = this.value;
    }
  }
};
</script>

<style>
</style>
